import React, { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { loginAuth } from "../api/loginApi";
import { ruta_historial, ruta_home } from "../constants/constants-rutas-codigo";
import Grid from "@mui/material/Grid";
import Alert from "@mui/material/Alert";
import { cambiarColores, encriptar, guardarLocalStorage, limpiarLocalStorage, obtenerLocalStorage } from "../resources/funciones";
import { CONST_CODIGO_PARAMETRO, CONST_CUENTA_ADMIN, CONST_CUENTA_PSWD } from "../constants/constants-codigo";
import { DataContext } from "../context/DataContext";

//Resources
import CustomInputFile from '../resources/customInputFile'
import caching from "../resources/caching";

export const Login = () => {

  const navigate = useNavigate();
  const [mostrarError, setMostrarError] = useState(false);
  const [mensaje, setMensaje] = useState("");

  const resetPassword = () => {
    //console.log("resetear password");
  };

  const {
    register,
    handleSubmit,
    watch,
    reset,
    control,
    setValue,
    formState: { errors },
  } = useForm({});


  const saveFileWrapper = (predefinedValue, saveFile = () => { }) => {
    return (file) => {
      saveFile(predefinedValue, file);
    };
  }

  const deleteFileWrapper = (predefinedValue) => {
    return () => {
      setValue(predefinedValue, null);
    };
  }

  const onLogin = async () => {
    const dataPOST = {
      user: watch("Email"),
      password: watch("Password"),
      parametro: CONST_CODIGO_PARAMETRO
    }

    const dataPOSTEncriptado = {
      body: encriptar(dataPOST)
    }
    // console.log('dataPOST', dataPOST)
    // const {status, message, data} = await loginAuth(dataPOST);
    const respuesta = await loginAuth(dataPOSTEncriptado);
    const { status, message, data } = respuesta
    // console.log(respuesta)
    // console.log("status", status)
    // console.log("message", message)
    if (status === 1) {




      guardarLocalStorage(data, "usuario")


      const estilosCompania = data?.estilo;
      if (estilosCompania) {

        const colores = [
          { name: '--color-fondo-menu-primario', color: estilosCompania?.fondo_menu },
          { name: '--color-texto-nav-item', color: estilosCompania?.text_opciones },
          { name: '--color-fondo-nav-item-activo', color: estilosCompania?.fond_opc_activo },
          { name: '--color-texto-nav-item-activo', color: estilosCompania?.text_opc_activo },
          { name: '--color-button-primario', color: estilosCompania?.boton_primario },
          { name: '--color-button-primario-hover', color: estilosCompania?.boton_primario_activo },
          { name: '--color-button-primario-text', color: estilosCompania?.text_boton_primario },
          { name: '--color-button-secundario', color: estilosCompania?.boton_secundario },
          { name: '--color-button-secundario-hover', color: estilosCompania?.boton_secundario_activo },
          { name: '--color-button-secundario-text', color: estilosCompania?.text_boton_secundario },
        ];

        cambiarColores(colores);
      }


      navigate(ruta_home);
      caching()
    } else {
      setMostrarError(true);
      setMensaje(message); 
    }


  };

  useEffect(() => {
    limpiarLocalStorage("usuario")
  }, [])

  return (
    <div className="container-fluid login"> 

      <div className="row login-height d-flex justify-content-end">
        <div className="col-12 col-md-7 col-lg-5 d-flex justify-content-center align-items-center bg-overlay">
          <div style={{ paddingTop: "25px" }}>
            <h2 className="text-white text-center">
              Sistema de Inmatriculación
            </h2>
            <h2 className="d-flex justify-content-center text-white">Vehicular</h2>
            <div className="col-7 w-100 d-flex justify-content-center  pt-5">

              <form className="g-3 col-8" onSubmit={handleSubmit(onLogin)}>
             
                <div className="mb-3">
                  <input
                    type="email"
                    placeholder="Email"
                    autoFocus={true}
                    className="form-control"
                    id="exampleInputEmail1"
                    aria-describedby="emailHelp"

                    {...register("Email", { required: true })} />
                </div>
                <div className="mb-3">
                  <input
                    type="password"
                    placeholder="Password"
                    className="form-control"
                    id="exampleInputPassword1"

                    {...register("Password", { required: true })} />
                </div>
                {mostrarError && (
                  <div className="row my-3">
                    <div className="col-12">
                      <Grid item xs={6}>
                        <Alert variant="outlined" severity="warning">
                          <span className="text-white">{mensaje}</span>
                        </Alert>
                      </Grid>
                    </div>
                  </div>
                )}
                <button type="submit" className="btn btn-main col-12" onClick={handleSubmit(onLogin)}>
                  Iniciar Sesion
                </button>
              </form>
            </div>

            <div className="text-white text-center p-5 ">
              <img src="/images/logo.png" alt="" width={"300px"} />
            </div>
          </div>

        </div>

      </div>
    </div>
  );


  /*
    return (
      <div className="container-fluid">
     
        <div className="row login-height">
          <div className="col-12 col-md-8 login-bk1 d-flex justify-content-center align-items-center">
            <div className="row justify-content-center pb-5">
              <div className="col-7 pb-5">
                <h1 className="d-flex justify-content-center">
                  Sistema de Inmatriculacion
                </h1>
                <h1 className="d-flex justify-content-center">Vehicular</h1>
              </div>
              <div className="col-7">
                <div>
                  <img src="/images/login2.png" width={"100%"} />
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-4 d-flex justify-content-center align-items-center">
            <div className="col-7">
              <form className="g-3" onSubmit={handleSubmit(onLogin)}>
                <h2 className="pb-2">Bienvenido</h2> <h5>Inicie Sesion</h5>
                <div className="mb-3">
                  <input
                    type="email"
                    placeholder="Email"
                    autoFocus={true}
                    className="form-control"
                    id="exampleInputEmail1"
                    aria-describedby="emailHelp"
                    //defaultValue={CONST_CUENTA_ADMIN}
                    {...register("Email", {required: true})}
                  />
                </div>
                <div className="mb-3">
                  <input
                    type="password"
                    placeholder="Password"
                    className="form-control"
                    id="exampleInputPassword1"
                    //defaultValue={CONST_CUENTA_PSWD}
                    {...register("Password", {required: true})}
                  />
                </div>
                {mostrarError && (
                  <div className="row my-3">
                    <div className="col-12">
                      <Grid item xs={6}>
                        <Alert variant="outlined" severity="warning">
                          {mensaje}
                        </Alert>
                      </Grid>
                    </div>
                  </div>
                )}
                <button type="submit" className="btn btn-main col-12" onClick={handleSubmit(onLogin)}>
                  Login
                </button>
                  
  
                  {/* <div className="mb-3 mt-3 float-end">
                    <label
                      className="form-check-label subrayado"
                      for="exampleCheck1"
                      onClick={resetPassword}
                    >
                      Olvide password
                    </label>
                  </div> }
              </form>            
            </div>          
          </div>
          {/* <div className="mt-2">
            <CustomInputFile file={watch("MyCustomFile")} savefile={saveFileWrapper("MyCustomFile",setValue)} deleteFile={deleteFileWrapper("MyCustomFile")} acceptFiles=".pdf" {...register("MyCustomFile")}/>
          </div> }
        </div>
      </div>
    );
  */

};

 