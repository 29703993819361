import React, { useContext, useEffect, useState } from "react";
import { Controller } from "react-hook-form";
import { DataContext } from "../../context/DataContext";
import {
  inputNumber,
  inputNumberDNI,
  inputText,
  inputTextDomicilioRazonSocial,
  isJustLAndN,
  isJustLAndN2,
  isValidEmail,
  limpiarLocalStorage,
  obtenerLocalStorage,
} from "../../resources/funciones";
import { DNIInput, FileFormats } from "../layout/DNIInput";
import { FileInput } from "../layout/FileInput";
import { consultaReniec, consultaSunat } from "../../api/tramiteApi";
import Select from 'react-select';


export const TramitePersona = ({
  index,
  register,
  errors,
  reset,
  watch,
  control,
  setValue,
  compradoresRemove,
  accion,
}) => {
  const { listaUbigeo, listaTipoDocumentos, listaTipoEstadoCivil, listaOficinasReg } = useContext(DataContext);
  const datosTramiteLS = obtenerLocalStorage("tramite")



  // const [obtenerUbigeo, setobtenerUbigeo] = useState(listaUbigeo.find((u) => u.TUbiCod === watch(`Compradores.${index}.Ubigeo`)) || datosTramiteLS?.dataCompradores?.Compradores?.[index]?.Ubigeo)



  // setValue(`Compradores.${index}.Ubigeo`,defaultOption)
  /*
  
    const setUbigeoDefault = async (indice) => {
      console.log("setUbigeoDefault indice ", indice);
  
  
      let ubigeoEncontrado = listaUbigeo.find((u) => u.TUbiCod === "140124")
      console.log("setUbigeoDefault ubigeoEncontrado ", ubigeoEncontrado);
      setValue(`Compradores.${indice}.Ubigeo`, ubigeoEncontrado)
      setobtenerUbigeo(ubigeoEncontrado)
  
  
      console.log("setUbigeoDefault ubigeoEncontrado actualizo on blurr ", ubigeoEncontrado);
  
      //setobtenerUbigeo(ubigeoEncontrado)
    }
    //let ubigeoEncontrado = listaUbigeo.find((u) => u.TUbiCod === watch(`Compradores.${index}.CodUbig`)) || datosTramiteLS?.dataCompradores?.Compradores?.[index]?.Ubigeo;
  */

  // const obtenerUbigeoCony = listaUbigeo.find((u) => u.TUbiCod === watch(`Compradores.${index}.ConyUbigeo`)) || datosTramiteLS?.dataCompradores?.Compradores?.[index]?.ConyUbigeo;
  const handleEmailValidation = (email) => {
    const isValid = isValidEmail(email);
    return isValid;
  };


  useEffect(() => {
    if (accion === "agregar") {
      if (datosTramiteLS === null) {
        setValue(`Compradores.${index}.SepPatr`, "N");
        setValue(`Compradores.${index}.UnionHecho`, "N");
        if (watch(`Compradores.${index}.EstCivil`) !== "C") {
          setValue(`Compradores.${index}.SocCony`, "N");
          setValue(`Compradores.${index}.ConyEstCivil`, "S");
        } else {
          setValue(`Compradores.${index}.SocCony`, "S");
          setValue(`Compradores.${index}.ConyEstCivil`, "C");
        }
      } else {
        limpiarLocalStorage("tramite");
      }
    }
  }, [watch(`Compradores.${index}.EstCivil`)]);



  useEffect(() => {
    if (watch(`Compradores.${index}.EstCivil`) === "C") {
      if (watch(`Compradores.${index}.SepPatr`) === "S") {
        setValue(`Compradores.${index}.SocCony`, "N");
        setValue(`Compradores.${index}.ConyTipoDoc`, "");
        setValue(`Compradores.${index}.ConyNumDoc`, "");
      } else {
        setValue(`Compradores.${index}.SocCony`, "S");
        setValue(`Compradores.${index}.ConyTipoDoc`, "D");
        setValue(`Compradores.${index}.PartReg`, "");
        setValue(`Compradores.${index}.OficReg`, "");

      }
    }
  }, [watch(`Compradores.${index}.SepPatr`)]);


  useEffect(() => {
    const tipoInm = watch("TipoInmatriculacion")
    if (accion === "agregar") {
      if (tipoInm === 'J' || tipoInm === 'JL') {
        setValue(`Compradores.${index}.TipoDoc`, 'R')
        setValue(`Compradores.${index}.NumDoc`, '')
        setValue(`Compradores.${index}.ApellidoP`, '')
        setValue(`Compradores.${index}.ApellidoM`, '')
        setValue(`Compradores.${index}.Nombre`, '')
        setValue(`Compradores.${index}.Ubigeo`, '')
        setValue(`Compradores.${index}.Domicilio`, '')
        setValue(`Compradores.${index}.Telefono`, '')
        setValue(`Compradores.${index}.Email`, '')
        setValue(`Compradores.${index}.PartReg`, '')
        setValue(`Compradores.${index}.RepNumDoc`, '')
        setValue(`Compradores.${index}.RepApellidoP`, '')
        setValue(`Compradores.${index}.RepApellidoM`, '')
        setValue(`Compradores.${index}.RepNombre`, '')
        setValue(`Compradores.${index}.RepTelefono`, '')
        setValue(`Compradores.${index}.RepEmail`, '')
      } else {
        setValue(`Compradores.${index}.TipoDoc`, 'D')
        setValue(`Compradores.${index}.ConyTipoDoc`, 'D');
        setValue(`Compradores.${index}.NumDoc`, '')
        setValue(`Compradores.${index}.ApellidoP`, '')
        setValue(`Compradores.${index}.ApellidoM`, '')
        setValue(`Compradores.${index}.Nombre`, '')
        setValue(`Compradores.${index}.Ubigeo`, '')
        setValue(`Compradores.${index}.Domicilio`, '')
        setValue(`Compradores.${index}.Telefono`, '')
        setValue(`Compradores.${index}.Email`, '')
        setValue(`Compradores.${index}.ConyNumDoc`, '')
        setValue(`Compradores.${index}.ConyApellidoP`, '')
        setValue(`Compradores.${index}.ConyApellidoM`, '')
        setValue(`Compradores.${index}.ConyNombre`, '')
        setValue(`Compradores.${index}.ConyUbigeo`, '')
        setValue(`Compradores.${index}.ConyDomicilio`, '')
        setValue(`Compradores.${index}.ConyTelefono`, '')
        setValue(`Compradores.${index}.ConyEmail`, '')
        setValue(`Compradores.${index}.ConyPartReg`, '')
      }
    } else {// ENTRA CUANDO EDITAMOS UNA INMATRICULACION
      if (tipoInm === 'J' || tipoInm === 'JL') {
        setValue(`Compradores.${index}.TipoDoc`, 'R')
        setValue(`Compradores.${index}.Ubigeo`, (listaUbigeo.find((u) => u.TUbiCod === watch(`Compradores.${index}.Ubigeo`))))
      } else {
        setValue(`Compradores.${index}.TipoDoc`, watch(`Compradores.${index}.TipoDoc`))
        setValue(`Compradores.${index}.ConyTipoDoc`, watch(`Compradores.${index}.ConyTipoDoc`));
        setValue(`Compradores.${index}.Ubigeo`, (listaUbigeo.find((u) => u.TUbiCod === watch(`Compradores.${index}.Ubigeo`))))
        if (watch(`Compradores.${index}.ConyUbigeo`)?.length > 0) {
          setValue(`Compradores.${index}.ConyUbigeo`, (listaUbigeo.find((u) => u.TUbiCod === watch(`Compradores.${index}.ConyUbigeo`))))
        }

      }
    }
  }, [watch("TipoInmatriculacion")])


  return (
    <>
      {watch(`Compradores`).length > 1 ? (
        <h5 className="pt-3">Datos del Comprador {index + 1}</h5>
      ) : (
        <h5 className="pt-3">Datos del Comprador</h5>
      )}
      <div className="row form-section border border-2 mb-3 py-3">
        {watch("TipoInmatriculacion") === "N" ? (
          <div key={`comprador_${index}`} className="row ">
            <div className="col-md-2">
              <label className="form-label">Documento</label>
              <select
                className="form-select"
                aria-label="Default select example"
                {...register(`Compradores.${index}.TipoDoc`, {
                  required: true,
                  onChange: (e) => {
                    if (accion === 'agregar') {
                      const selectedValue = e.target.value;
                      if (selectedValue) {
                        setValue(`Compradores.${index}.NumDoc`, '');
                      } else {
                        setValue(`Compradores.${index}.NumDoc`, watch(`Compradores.${index}.NumDoc`));
                      }
                    }
                  }
                })}
              >
                <option value='D'>DNI</option>
                <option value='CE'>Carnet de Extranjeria</option>
                <option value='P'>Pasaporte</option>
              </select>
              {errors.Compradores?.[index]?.TipoDoc && (
                <span className="error">Especificar el tipo de documento</span>
              )}
            </div>
            <div className="col-md-2">
              <label className="form-label">Número</label>
              <input
                className="form-control"
                onKeyDown={e => {
                  if (watch(`Compradores.${index}.TipoDoc`) === 'D') {
                    inputNumberDNI(e)
                  } else if (watch(`Compradores.${index}.TipoDoc`) === 'CE') {
                    isJustLAndN2(e)
                  } else {
                    isJustLAndN2(e)
                  }
                }}
                {...register(`Compradores.${index}.NumDoc`, {
                  required: "El numero es requerido",
                  validate: (value) => {
                    if (watch(`Compradores.${index}.TipoDoc`) === 'D') {
                      if (value.trim().length !== 8) {
                        return 'El numero de DNI debe ser 8 digitos'
                      } else {
                        return true
                      }
                    } else if (watch(`Compradores.${index}.TipoDoc`) === 'CE') {
                      if (value.trim().length < 5) {
                        return 'El número de carnet de extranjería debe tener al menos 5 dígitos';
                      } else if (value.trim().length > 12) {
                        return 'El número de carnet de extranjería no puede tener más de 12 dígitos';
                      } else {
                        return true;
                      }
                    } else {
                      if (value.trim().length < 5) {
                        return 'El número de pasaporte debe tener al menos 5 dígitos';
                      } else if (value.trim().length > 12) {
                        return 'El número de pasaporte no puede tener más de 12 dígitos';
                      } else {
                        return true;
                      }
                    }
                  }
                }
                )}


                onBlur={async () => {
                  let dni = watch(`Compradores.${index}.NumDoc`)
                  let tipo = watch(`Compradores.${index}.TipoDoc`)
                  let apellido = watch(`Compradores.${index}.ApellidoP`)
                  let dataPost = {
                    "dni": dni,
                    "tipo": tipo
                  }
                  if (dni.length == 8 && apellido.length == 0 && tipo == "D") {
                    const data = await consultaReniec(dataPost)
                    setValue(`Compradores.${index}.Nombre`, data.nombre)
                    setValue(`Compradores.${index}.ApellidoP`, data.apellidop)
                    setValue(`Compradores.${index}.ApellidoM`, data.apellidom)
                    setValue(`Compradores.${index}.EstCivil`, data.estcivil)
                    setValue(`Compradores.${index}.Ubigeo`, data.ubigeo)
                    setValue(`Compradores.${index}.Domicilio`, data.direccion)

                  }
                  /*
                  if (watch(`Compradores.${index}.TipoDoc`) === 'D' && dni.length === 0) {
                    setValue(`Compradores.${index}.ApellidoP`, '')
                    setValue(`Compradores.${index}.ApellidoM`, '')
                    setValue(`Compradores.${index}.Nombre`, '')
                  }
                    */
                }}

              />
              {errors.Compradores?.[index]?.NumDoc && (
                <span className="error">{errors.Compradores[index].NumDoc.message}</span>
              )}
            </div>
            <div className="col-md-2">
              <label className="form-label">Ap. Paterno</label>
              <input
                type="text"
                className="form-control"
                onKeyDown={e => inputText(e)}
                {...register(`Compradores.${index}.ApellidoP`, {
                  required: true,
                  validate: (value) => {
                    if (value.length === 1) {
                      return false
                    } else {
                      return true
                    }
                  }
                })}
              />
              {errors.Compradores?.[index]?.ApellidoP && (
                <span className="error">El apellido paterno es requerido</span>
              )}
            </div>
            <div className="col-md-2">
              <label className="form-label">Ap. Materno</label>
              <input
                type="text"
                className="form-control"
                onKeyDown={e => inputText(e)}
                {...register(`Compradores.${index}.ApellidoM`, {
                  validate: (value) => {
                    if (watch(`Compradores.${index}.TipoDoc`) === 'D') {
                      if (value.length === 1) {
                        return false
                      } else {
                        return true
                      }
                    }
                  }
                })}
              />

            </div>
            <div className="col-md-4">
              <label className="form-label">Nombres</label>
              <input
                type="text"
                className="form-control"
                onKeyDown={e => inputText(e)}
                {...register(`Compradores.${index}.Nombre`, {
                  required: true,
                  validate: (value) => {
                    if (value.length === 1) {
                      return false
                    } else {
                      return true
                    }
                  }
                })}
              />
              {errors.Compradores?.[index]?.Nombre && (
                <span className="error">El nombre es requerido</span>
              )}
            </div>
            <div className="col-md-6">
              <b>
                <label className="form-label">
                  Imagen frontal del documento de identidad(valido)
                </label>
              </b>
              <DNIInput
                id={`Compradores.${index}.ImagenFrontal`}
                register={register}
                refText={`Compradores.${index}.ImagenFrontal`}
                fileName={datosTramiteLS?.datos_compradores?.[index] === undefined && datosTramiteLS?.dataCompradores?.Compradores?.[index] === undefined ? undefined : `DNI_FRONTAL_${datosTramiteLS?.idTramite}_${index + 1}`}
                format={FileFormats.IMAGE}
                formatError={"El archivo no es una Imagen"}

                otherValidation={accion}
              />
              {errors.Compradores?.[index]?.ImagenFrontal && (
                <span className="error">{errors.Compradores?.[index]?.ImagenFrontal.message}</span>
              )}
            </div>
            <div className="col-md-6">
              <b>
                <label className="form-label">
                  Imagen dorsal del documento de identidad(valido)
                </label>
              </b>
              <DNIInput
                id={`Compradores.${index}.ImagenDorsal`}
                register={register}
                refText={`Compradores.${index}.ImagenDorsal`}
                fileName={datosTramiteLS?.datos_compradores?.[index] === undefined && datosTramiteLS?.dataCompradores?.Compradores?.[index] === undefined ? undefined : `DNI_DORSAL_${datosTramiteLS?.idTramite}_${index + 1}`}
                format={FileFormats.IMAGE}
                formatError={"El archivo no es una Imagen"}
                otherValidation={accion}
              />
              {errors.Compradores?.[index]?.ImagenDorsal && (
                <span className="error">{errors.Compradores?.[index]?.ImagenDorsal.message}</span>
              )}
            </div>
          </div>
        ) : (
          <div key={`comprador_${index}`} className="row ">
            <div className="col-md-2">
              <label className="form-label">Documento</label>
              <select
                className="form-select"
                defaultValue="R"
                {...register(`Compradores.${index}.TipoDoc`, {
                  required: true,
                })}
              >
                <option value="R" >
                  RUC
                </option>
              </select>
              {errors.Compradores?.[index]?.TipoDoc && (
                <span className="error">Especificar el tipo de documento</span>
              )}
            </div>
            <div className="col-md-2">
              <label className="form-label">Numero</label>
              <input
                type="number"
                className="form-control"
                onKeyDown={inputNumber}
                {...register(`Compradores.${index}.NumDoc`, {
                  required: "El numero de RUC es requerido",
                  validate: (value) => {
                    if (value.length !== 11) {
                      return 'El numero de RUC debe tener 11 digitos'
                    } else {
                      return true
                    }
                  }
                })}

                onBlur={async () => {
                  let ruc = watch(`Compradores.${index}.NumDoc`)
                  let dataPost = {
                    "ruc": ruc,
                  }
                  if (ruc.length == 11) {
                    const data = await consultaSunat(dataPost)
                    setValue(`Compradores.${index}.RazonSocial`, data.razonSocial)

                  }
                  /*
                  if (watch(`Compradores.${index}.TipoDoc`) === 'D' && dni.length === 0) {
                    setValue(`Compradores.${index}.ApellidoP`, '')
                    setValue(`Compradores.${index}.ApellidoM`, '')
                    setValue(`Compradores.${index}.Nombre`, '')
                  }
                    */
                }}


              />
              {errors.Compradores?.[index]?.NumDoc && (
                <span className="error">{errors.Compradores[index].NumDoc.message}</span>
              )}
            </div>
            <div className="col-md-4">
              <label className="form-label">Razón Social</label>
              <input
                type="text"
                className="form-control"
                onKeyDown={e => inputTextDomicilioRazonSocial(e)}
                {...register(`Compradores.${index}.RazonSocial`, {
                  required: true,
                  validate: (value) => {
                    if (value.length === 1) {
                      return false
                    } else {
                      return true
                    }
                  }
                })}
              />
              {errors.Compradores?.[index]?.RazonSocial && (
                <span className="error">La razón social es requerida</span>
              )}
            </div>
            <div className="col-md-2">
              <label className="form-label">Partida</label>
              <input
                type="text"
                className="form-control"
                onKeyDown={e => isJustLAndN(e)}
                {...register(`Compradores.${index}.PartReg`, {
                  required: "Especificar la partida registral",
                  validate: (value) => {
                    if (value.trim().length < 2) {
                      return 'El número de partida debe tener al menos 2 dígitos';
                    } else if (value.trim().length > 15) {
                      return 'El número de partida no puede tener más de 15 dígitos';
                    } else {
                      return true;
                    }
                  }
                })}
              />
              {errors.Compradores?.[index]?.PartReg && (
                <span className="error">{errors.Compradores[index].PartReg.message}</span>
              )}
            </div>
            <div className="col-md-2">
              <label className="form-label">Oficina</label>
              <select
                className="form-select"
                aria-label="Default select example"
                {...register(`Compradores.${index}.OficReg`, {
                  required: true,
                })}
              >
                {listaOficinasReg?.map((item, key) => {
                  if (item.OficEst === "S") {
                    return <option key={key} value={item.OficCod}>{item.OficDes}</option>;
                  } else {
                    return null
                  }
                })}
              </select>
              {errors.Compradores?.[index]?.OficReg && (
                <span className="error">Especificar la oficina registral</span>
              )}
            </div>
          </div>
        )}
        <div className="col-md-6">
          <label className="form-label">Distrito</label>
          <div className="">

            {listaUbigeo.length > 0 &&

              <Controller
                name={`Compradores.${index}.Ubigeo`}
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <Select
                    {...field}
                    isClearable={true}
                    options={listaUbigeo}
                    getOptionLabel={(option) => option.TUbiDes} // Definir qué mostrar como etiqueta
                    getOptionValue={(option) => option.TUbiCod}
                    placeholder="Ingrese el distrito"

                  />
                )}
              />
            }
          </div>
          {errors.Compradores?.[index]?.Ubigeo && (
            <span className="error">El distrito es requerido</span>
          )}
        </div>
        <div className="col-md-6">
          <label className="form-label">Domicilio</label>
          <input
            type="text"
            className="form-control"
            onKeyDown={e => inputTextDomicilioRazonSocial(e)}
            {...register(`Compradores.${index}.Domicilio`, {
              required: true,
              validate: (value) => {
                if (value.length === 1) {
                  return false
                } else {
                  return true
                }
              }
            })}
          />
          {errors.Compradores?.[index]?.Domicilio && (
            <span className="error">El domicilio es requerido</span>
          )}
        </div>
        {watch("TipoInmatriculacion") === "N" ? (
          <>
            <div className="col-md-2">
              <label className="form-label">Celular</label>
              <input
                className="form-control"
                type="number"
                onKeyDown={inputNumber}
                {...register(`Compradores.${index}.Telefono`, {
                  required: 'El telefono es requerido',
                  validate: (value) => {
                    if (value.length !== 9) {
                      return 'El numero de telefono debe tener 9 digitos'
                    } else {
                      return true
                    }
                  }
                })}
              />
              {errors.Compradores?.[index]?.Telefono && (
                <span className="error">{errors.Compradores[index].Telefono.message}</span>
              )}
            </div>
            <div className="col-md-4">
              <label className="form-label">Email</label>
              <input
                type="email"
                className="form-control"
                {...register(`Compradores.${index}.Email`, {
                  required: true,
                  validate: handleEmailValidation,
                })}
              />
              {errors.Compradores?.[index]?.Email &&
                errors.Compradores?.[index]?.Email.type === "required" && (
                  <p role="alert" className="error">
                    El email es requerido
                  </p>
                )}
              {errors.Compradores?.[index]?.Email &&
                errors.Compradores?.[index]?.Email.type === "validate" && (
                  <p role="alert" className="error">
                    Email incorrecto
                  </p>
                )}
            </div>
            <div className="col-md-3">
              <label className="form-label">Estado Civil</label>
              <select
                className="form-select"
                aria-label="Default select example"
                {...register(`Compradores.${index}.EstCivil`, {
                  required: true,
                })}
                defaultValue={"S"}
              >
                <option value='C'>Casado</option>
                <option value='D'>Divorciado</option>
                <option value='S'>Soltero</option>
                <option value='V'>Viudo</option>
              </select>
              {errors.Compradores?.[index]?.EstCivil && (
                <span className="error">El estado civil es requerido</span>
              )}
            </div>
            {watch(`Compradores.${index}.EstCivil`) === "C" ? (
              <>
                <div className="col-md-3">
                  <label className="form-label">Sep. de Patrimonios</label>
                  <select
                    className="form-select"
                    aria-label="Default select example"
                    {...register(`Compradores.${index}.SepPatr`, {
                      required: true,
                    })}
                  >
                    <option value="N">No</option>
                    <option value="S">Sí</option>
                  </select>
                  {errors.Compradores?.[index]?.SepPatr && (
                    <span className="error">
                      La Sep. de Patrimonios es requerido
                    </span>
                  )}
                </div>
              </>
            ) : (
              <>
                <div className="col-md-3">
                  <label className="form-label">Union de Hecho</label>
                  <select
                    className="form-select"
                    aria-label="Default select example"
                    {...register(`Compradores.${index}.UnionHecho`, {
                      required: true,
                    })}
                  >
                    <option value="N">No</option>
                    <option value="S">Sí</option>
                  </select>
                  {errors.Compradores?.[index]?.UnionHecho && (
                    <span className="error">
                      La Union de Hecho es requerido
                    </span>
                  )}
                </div>
              </>
            )}
            {((watch(`Compradores.${index}.EstCivil`) === "C" &&
              watch(`Compradores.${index}.SepPatr`) === "S") ||
              (watch(`Compradores.${index}.EstCivil`) !== "C" &&
                watch(`Compradores.${index}.UnionHecho`) === "S")) && (
                <>
                  <div className="col-md-2">
                    <label className="form-label">Partida</label>
                    <input
                      type="text"
                      className="form-control"
                      onKeyDown={e => isJustLAndN(e)}
                      {...register(`Compradores.${index}.PartReg`, {
                        required: "Especificar la partida registral",
                        validate: (value) => {
                          if (value.trim().length < 2) {
                            return 'El número de partida debe tener al menos 2 dígitos';
                          } else if (value.trim().length > 15) {
                            return 'El número de partida no puede tener más de 15 dígitos';
                          } else {
                            return true;
                          }
                        }
                      })}
                    />
                    {errors.Compradores?.[index]?.PartReg && (
                      <span className="error">{errors.Compradores[index].PartReg.message}</span>
                    )}
                  </div>
                  <div className="col-md-2">
                    <label className="form-label">Oficina</label>
                    <select
                      className="form-select"
                      aria-label="Default select example"
                      {...register(`Compradores.${index}.OficReg`, {
                        required: true,
                      })}
                    >
                      {listaOficinasReg?.map((item, key) => {
                        if (item.OficEst === "S") {
                          return (
                            <option key={key} value={item.OficCod}>{item.OficDes}</option>
                          )
                        } else {
                          return null
                        }
                      })}
                    </select>
                    {errors.Compradores?.[index]?.OficReg && (
                      <span className="error">
                        Especificar la oficina registral
                      </span>
                    )}
                  </div>
                  <div className="col-md-8">
                    <label className="form-label">Archivo</label>
                    <FileInput
                      id={`inputFileArchivo${index}`}
                      register={register}
                      refText={`Compradores.${index}.pdf`}
                      fileName={datosTramiteLS?.datos_tramite?.[index] === undefined && datosTramiteLS?.dataCompradores?.Compradores?.[index] === undefined ? undefined : `Archivo_${datosTramiteLS?.idTramite}_${index + 1}`}
                      format={FileFormats.PDF}
                      formatError={"El archivo no es un PDF"}
                      min={0}
                      minError={"El archivo no debe ser menor a 0 MB"}
                      max={4 * 1024 * 1024}
                      maxError={"El archivo no debe ser mayor a 4 MB"}
                      otherValidation={accion}
                    />
                    {errors.Compradores?.[index]?.pdf && (
                      <span className="error">{errors.Compradores?.[index]?.pdf?.message}</span>
                    )}


                  </div>
                </>
              )}
            {((watch(`Compradores.${index}.EstCivil`) === "C" &&
              watch(`Compradores.${index}.SepPatr`) === "N") ||
              (watch(`Compradores.${index}.EstCivil`) !== "C" &&
                watch(`Compradores.${index}.UnionHecho`) === "S")) && (
                <>
                  <h5 className="pt-3">Datos del Cónyuge</h5>
                  <div className="col-md-2">
                    <label className="form-label">Documento</label>
                    <select
                      className="form-select"
                      aria-label="Default select example"
                      {...register(`Compradores.${index}.ConyTipoDoc`, {
                        required: true,
                        onChange: (e) => {
                          if (accion === 'agregar') {
                            const selectedValue = e.target.value;
                            if (selectedValue !== '') {
                              setValue(`Compradores.${index}.ConyNumDoc`, '');
                            } else {
                              setValue(`Compradores.${index}.ConyNumDoc`, watch(`Compradores.${index}.ConyNumDoc`));
                            }
                          }
                        }
                      })}
                    >
                      {listaTipoDocumentos.map((tipDoc) => {
                        if (tipDoc.TipDocEst === "S") {
                          return (
                            <option
                              value={tipDoc.TipDocCod}
                            >
                              {tipDoc.TipDocDes}
                            </option>
                          )
                        } else {
                          return null
                        }
                      })}
                    </select>
                    {errors.Compradores?.[index]?.ConyTipoDoc && (
                      <span className="error">
                        Especificar el tipo de documento
                      </span>
                    )}
                  </div>
                  <div className="col-md-2">
                    <label className="form-label">Número</label>
                    <input
                      className="form-control"
                      onKeyDown={e => {
                        if (watch(`Compradores.${index}.ConyTipoDoc`) === 'D') {
                          inputNumberDNI(e)
                        } else if ((watch(`Compradores.${index}.ConyTipoDoc`) === 'CE')) {
                          isJustLAndN2(e)
                        } else {
                          isJustLAndN2(e)
                        }
                      }}
                      {...register(`Compradores.${index}.ConyNumDoc`, {
                        required: "El numero de DNI es requerido",
                        validate: (value) => {
                          if (watch(`Compradores.${index}.ConyTipoDoc`) === 'D') {
                            if (value.trim().length !== 8) {
                              return 'El numero de DNI debe ser 8 digitos'
                            } else {
                              return true
                            }
                          } else if (watch(`Compradores.${index}.ConyTipoDoc`) === 'CE') {
                            if (value.trim().length < 5) {
                              return 'El número de carnet de extranjería debe tener al menos 5 dígitos';
                            } else if (value.trim().length > 12) {
                              return 'El número de carnet de extranjería no puede tener más de 12 dígitos';
                            } else {
                              return true;
                            }
                          } else {
                            if (value.trim().length < 5) {
                              return 'El número de pasaporte debe tener al menos 5 dígitos';
                            } else if (value.trim().length > 12) {
                              return 'El número de pasaporte no puede tener más de 12 dígitos';
                            } else {
                              return true;
                            }
                          }
                        }
                      }
                      )}


                      /**
                       * 
                       * 
                       * 
                       * onBlur={async () => {
                        const valor = watch(`Compradores.${index}.ConyNumDoc`)
                        const dataPost = {
                          "dni": valor
                        }
                        if (watch(`Compradores.${index}.ConyTipoDoc`) === 'D' && valor.length === 8) {
                          const data = await consultaReniec(dataPost)
                          setValue(`Compradores.${index}.ConyApellidoP`, data.apellidop)
                          setValue(`Compradores.${index}.ConyApellidoM`, data.apellidom)
                          setValue(`Compradores.${index}.ConyNombre`, data.nombre)
                        }
                        if (watch(`Compradores.${index}.ConyTipoDoc`) === 'D' && valor.length === 0) {
                          setValue(`Compradores.${index}.ConyApellidoP`, '')
                          setValue(`Compradores.${index}.ConyApellidoM`, '')
                          setValue(`Compradores.${index}.ConyNombre`, '')
                        }
                      }}
                 */

                      onBlur={async () => {
                        let dni = watch(`Compradores.${index}.ConyNumDoc`)
                        let tipo = watch(`Compradores.${index}.ConyTipoDoc`)
                        let apellido = watch(`Compradores.${index}.ConyApellidoP`)

                        if (dni.length >= 8 && apellido.length == 0) {
                          let dataPost = {
                            "dni": dni.trim(),
                            "tipo": tipo
                          }
                          const data = await consultaReniec(dataPost)
                          setValue(`Compradores.${index}.ConyNombre`, data.nombre)
                          setValue(`Compradores.${index}.ConyApellidoP`, data.apellidop)
                          setValue(`Compradores.${index}.ConyApellidoM`, data.apellidom)
                          setValue(`Compradores.${index}.ConyEstCivil`, data.estcivil)
                          setValue(`Compradores.${index}.ConyUbigeo`, data.ubigeo)
                          setValue(`Compradores.${index}.ConyDomicilio`, data.direccion)

                        }
                        /*
                        if (watch(`Compradores.${index}.TipoDoc`) === 'D' && dni.length === 0) {
                          setValue(`Compradores.${index}.ApellidoP`, '')
                          setValue(`Compradores.${index}.ApellidoM`, '')
                          setValue(`Compradores.${index}.Nombre`, '')
                        }
                          */
                      }}




                    />
                    {errors.Compradores?.[index]?.ConyNumDoc && (
                      <span className="error">{errors.Compradores[index].ConyNumDoc.message}</span>
                    )}
                  </div>
                  <div className="col-md-2">
                    <label className="form-label">Ap. Paterno</label>
                    <input
                      type="text"
                      className="form-control"
                      onKeyDown={e => inputText(e)}
                      {...register(`Compradores.${index}.ConyApellidoP`, {
                        required: true,
                        validate: (value) => {
                          if (value.length === 1) {
                            return false
                          } else {
                            return true
                          }
                        }
                      })}
                    />
                    {errors.Compradores?.[index]?.ConyApellidoP && (
                      <span className="error">
                        El apellido paterno es requerido
                      </span>
                    )}
                  </div>
                  <div className="col-md-2">
                    <label className="form-label">Ap. Materno</label>
                    <input
                      type="text"
                      className="form-control"
                      onKeyDown={e => inputText(e)}
                      {...register(`Compradores.${index}.ConyApellidoM`, {
                        required: watch(`Compradores.${index}.ConyTipoDoc`) === 'D',
                        validate: (value) => {
                          if (watch(`Compradores.${index}.ConyTipoDoc`) === 'D') {
                            if (value.length === 1) {
                              return false
                            } else {
                              return true
                            }
                          }
                        }
                      })}
                    />
                    {errors.Compradores?.[index]?.ConyApellidoM && (
                      <span className="error">
                        El apellido materno es requerido
                      </span>
                    )}
                  </div>
                  <div className="col-md-4">
                    <label className="form-label">Nombres</label>
                    <input
                      type="text"
                      className="form-control"
                      onKeyDown={e => inputText(e)}
                      {...register(`Compradores.${index}.ConyNombre`, {
                        required: true,
                        validate: (value) => {
                          if (value.length === 1) {
                            return false
                          } else {
                            return true
                          }
                        }
                      })}
                    />
                    {errors.Compradores?.[index]?.ConyNombre && (
                      <span className="error">El nombre es requerido</span>
                    )}
                  </div>
                  <div className="col-md-6">
                    <b>
                      <label className="form-label">
                        Imagen frontal del documento de identidad(valido)
                      </label>
                    </b>
                    <DNIInput
                      id={`Compradores.${index}.ConyImagenFrontal`}
                      register={register}
                      refText={`Compradores.${index}.ConyImagenFrontal`} // conyugue
                      fileName={datosTramiteLS?.datos_compradores?.[index].ConyTipoDoc === '' || (datosTramiteLS?.datos_tramite?.[index] === undefined && datosTramiteLS?.dataCompradores?.Compradores?.[index] === undefined) ? undefined : `Imagen_Frontal${datosTramiteLS?.idTramite}_${index + 1}`}

                      format={FileFormats.IMAGE}
                      formatError={"El archivo no es una Imagen"}
                      otherValidation={accion}
                      unionDeHecho={watch(`Compradores.${index}.UnionHecho`) === "S"}
                      ConyApellidoM={datosTramiteLS?.datos_compradores?.[index]?.ConyApellidoP}
                      accion={accion}
                    />
                    {errors.Compradores?.[index]?.ConyImagenFrontal && (
                      <span className="error">{errors.Compradores?.[index]?.ConyImagenFrontal.message}</span>
                    )}
                  </div>
                  <div className="col-md-6">
                    <b>
                      <label className="form-label">
                        Imagen dorsal del documento de identidad(valido)
                      </label>
                    </b>
                    <DNIInput
                      id={`Compradores.${index}.ConyImagenDorsal`}
                      register={register}
                      refText={`Compradores.${index}.ConyImagenDorsal`} // conyugue
                      fileName={datosTramiteLS?.datos_tramite?.[index] === undefined && datosTramiteLS?.dataCompradores?.Compradores?.[index] === undefined ? undefined : `Imagen_Dorsal${datosTramiteLS?.idTramite}_${index + 1}`}
                      format={FileFormats.IMAGE}
                      formatError={"El archivo no es una Imagen"}
                      otherValidation={accion}
                      unionDeHecho={watch(`Compradores.${index}.UnionHecho`) === "S"}
                      ConyApellidoM={datosTramiteLS?.datos_compradores?.[index]?.ConyApellidoP}
                      accion={accion}
                    />
                    {errors.Compradores?.[index]?.ConyImagenDorsal && (
                      <span className="error">{errors.Compradores?.[index]?.ConyImagenDorsal.message}</span>
                    )}
                  </div>
                  <div className="col-md-6">
                    <label className="form-label">Distrito</label>

                    <Controller
                      name={`Compradores.${index}.ConyUbigeo`}
                      control={control}
                      rules={{ required: true }}
                      render={({ field }) => (
                        <Select
                          {...field}
                          isClearable={true}
                          options={listaUbigeo}
                          getOptionLabel={(option) => option.TUbiDes} // Definir qué mostrar como etiqueta
                          getOptionValue={(option) => option.TUbiCod}
                          placeholder="Ingrese el distrito"
                        />
                      )}
                    />


                    {errors.Compradores?.[index]?.ConyUbigeo && (
                      <span className="error">El distrito es requerido</span>
                    )}
                  </div>
                  <div className="col-md-6">
                    <label className="form-label">Domicilio</label>
                    <input
                      type="text"
                      className="form-control"
                      onKeyDown={e => inputTextDomicilioRazonSocial(e)}
                      {...register(`Compradores.${index}.ConyDomicilio`, {
                        required: true,
                        validate: (value) => {
                          if (value.length === 1) {
                            return false
                          } else {
                            return true
                          }
                        }
                      })}
                    />
                    {errors.Compradores?.[index]?.ConyDomicilio && (
                      <span className="error">El domicilio es requerido</span>
                    )}
                  </div>
                  <div className="col-md-2">
                    <label className="form-label">Celular</label>
                    <input
                      type="number"
                      className="form-control"
                      onKeyDown={inputNumber}
                      {...register(`Compradores.${index}.ConyTelefono`, {
                        required: 'El telefono es requerido',
                        validate: (value) => {
                          if (value.length !== 9) {
                            return 'El numero de telefono debe tener 9 digitos'
                          } else {
                            return true
                          }
                        }
                      })}
                    />
                    {errors.Compradores?.[index]?.ConyTelefono && (
                      <span className="error">{errors.Compradores[index].ConyTelefono.message}</span>
                    )}
                  </div>
                  <div className="col-md-4">
                    <label className="form-label">Email</label>
                    <input
                      type="email"
                      className="form-control"
                      {...register(`Compradores.${index}.ConyEmail`, {
                        required: true,
                        validate: handleEmailValidation,
                      })}
                    />
                    {errors.Compradores?.[index]?.ConyEmail &&
                      errors.Compradores?.[index]?.ConyEmail.type === "required" && (
                        <p role="alert" className="error">
                          El email es requerido
                        </p>
                      )}
                    {errors.Compradores?.[index]?.ConyEmail &&
                      errors.Compradores?.[index]?.ConyEmail.type === "validate" && (
                        <p role="alert" className="error">
                          Email incorrecto
                        </p>
                      )}
                  </div>
                  <div className="col-md-3">
                    <label className="form-label">Estado Civil</label>
                    <select
                      className="form-select"
                      aria-label="Default select example"
                      defaultValue={
                        watch(`Compradores.${index}.EstCivil`) === "C" ? "C" : "S"
                      }
                      {...register(`Compradores.${index}.ConyEstCivil`, {
                        required: true,
                      })}
                    >
                      {listaTipoEstadoCivil.map((estCivil, index) => {
                        if (estCivil.EstCivEst === "S") {
                          return (
                            <option
                              key={index}
                              value={estCivil.EstCivCod}
                            >
                              {estCivil.EstCivDes}
                            </option>
                          )
                        } else {
                          return null
                        }
                      })}
                    </select>
                    {errors.Compradores?.[index]?.EstCivil && (
                      <span className="error">El estado civil es requerido</span>
                    )}
                  </div>
                </>
              )}
          </>
        ) : (
          <>
            {watch("TipoInmatriculacion") === "J" && (
              <>
                <h5 className="pt-4">Datos del Representante</h5>
                <div className="row">
                  <div className="col-md-2">
                    <label className="form-label">Documento</label>
                    <select
                      className="form-select"
                      aria-label="Default select example"
                      {...register(`Compradores.${index}.RepTipoDoc`, {
                        required: true,
                        onChange: (e) => {
                          if (accion === 'agregar') {
                            const selectedValue = e.target.value;
                            if (selectedValue !== '') {
                              setValue(`Compradores.${index}.RepNumDoc`, '');
                            } else {
                              setValue(`Compradores.${index}.RepNumDoc`, watch(`Compradores.${index}.RepNumDoc`));
                            }
                          }
                        }
                      })}
                    >
                      {listaTipoDocumentos.map((tipDoc, index) => {
                        if (tipDoc.TipDocEst === "S") {
                          return (
                            <option
                              key={index}
                              value={tipDoc.TipDocCod}
                            >
                              {tipDoc.TipDocDes}
                            </option>
                          )
                        } else {
                          return null
                        }
                      })}
                    </select>
                    {errors.Compradores?.[index]?.RepTipoDoc && (
                      <span className="error">
                        Especificar el tipo de documento
                      </span>
                    )}
                  </div>
                  <div className="col-md-2">
                    <label className="form-label">Número</label>
                    <input
                      className="form-control"
                      onKeyDown={e => {
                        if (watch(`Compradores.${index}.RepTipoDoc`) === 'D') {
                          inputNumberDNI(e)
                        } else if (watch(`Compradores.${index}.RepTipoDoc`) === 'CE') {
                          isJustLAndN2(e)
                        } else {
                          isJustLAndN2(e)
                        }
                      }}
                      {...register(`Compradores.${index}.RepNumDoc`, {
                        required: "El numero de DNI es requerido",
                        validate: (value) => {
                          if (watch(`Compradores.${index}.RepTipoDoc`) === 'D') {
                            if (value.trim().length !== 8) {
                              return 'El numero de DNI debe ser 8 digitos'
                            } else {
                              return true
                            }
                          } else if (watch(`Compradores.${index}.RepTipoDoc`) === 'CE') {
                            if (value.trim().length < 5) {
                              return 'El número de carnet de extranjería debe tener al menos 5 dígitos';
                            } else if (value.trim().length > 12) {
                              return 'El número de carnet de extranjería no puede tener más de 12 dígitos';
                            } else {
                              return true;
                            }
                          } else {
                            if (value.trim().length < 5) {
                              return 'El número de pasaporte debe tener al menos 5 dígitos';
                            } else if (value.trim().length > 12) {
                              return 'El número de pasaporte no puede tener más de 12 dígitos';
                            } else {
                              return true;
                            }
                          }
                        }
                      }
                      )}



                      onBlur={async () => {

                        let dni = watch(`Compradores.${index}.RepNumDoc`)
                        let tipo = watch(`Compradores.${index}.RepTipoDoc`)
                        let dataPost = {
                          "dni": dni,
                          "tipo": tipo
                        }
                        if (dni.length == 8 && tipo == "D") {
                          const data = await consultaReniec(dataPost)
                          setValue(`Compradores.${index}.RepNombre`, data.nombre)
                          setValue(`Compradores.${index}.RepApellidoP`, data.apellidop)
                          setValue(`Compradores.${index}.RepApellidoM`, data.apellidom) 
                        }
                      }}


                    /**
                     * 
                     * 
                     * 
                     * onBlur={async () => {
                      const valor = watch(`Compradores.${index}.RepNumDoc`)
                      const dataPost = {
                        "dni": valor
                      }
                      if (watch(`Compradores.${index}.RepTipoDoc`) === 'D' && valor.length === 8) {
                        const data = await consultaReniec(dataPost)
                        setValue(`Compradores.${index}.RepApellidoP`, data.apellidop)
                        setValue(`Compradores.${index}.RepApellidoM`, data.apellidom)
                        setValue(`Compradores.${index}.RepNombre`, data.nombre)
                      }
                      if (watch(`Compradores.${index}.RepTipoDoc`) === 'D' && valor.length === 0) {
                        setValue(`Compradores.${index}.RepApellidoP`, '')
                        setValue(`Compradores.${index}.RepApellidoM`, '')
                        setValue(`Compradores.${index}.RepNombre`, '')
                      }
                    }}

                    
                     */
                    />
                    {errors.Compradores?.[index]?.RepNumDoc && (
                      <span className="error">{errors.Compradores[index].RepNumDoc.message}</span>
                    )}
                  </div>
                  <div className="col-md-2">
                    <label className="form-label">Ap. Paterno</label>
                    <input
                      type="text"
                      className="form-control"
                      onKeyDown={e => inputText(e)}
                      {...register(`Compradores.${index}.RepApellidoP`, {
                        required: true,
                        validate: (value) => {
                          if (value.length === 1) {
                            return false
                          } else {
                            return true
                          }
                        }
                      })}
                    />
                    {errors.Compradores?.[index]?.RepApellidoP && (
                      <span className="error">
                        El apellido paterno es requerido
                      </span>
                    )}
                  </div>
                  <div className="col-md-2">
                    <label className="form-label">Ap. Materno</label>
                    <input
                      type="text"
                      className="form-control"
                      onKeyDown={e => inputText(e)}
                      {...register(`Compradores.${index}.RepApellidoM`, {
                        required: watch(`Compradores.${index}.RepTipoDoc`) === 'D',
                        validate: (value) => {
                          if (watch(`Compradores.${index}.RepTipoDoc`) === 'D') {
                            if (value.length === 1) {
                              return false
                            } else {
                              return true
                            }
                          }
                        }
                      })}
                    />
                    {errors.Compradores?.[index]?.RepApellidoM && (
                      <span className="error">
                        El apellido materno es requerido
                      </span>
                    )}
                  </div>
                  <div className="col-md-4">
                    <label className="form-label">Nombres</label>
                    <input
                      type="text"
                      className="form-control"
                      onKeyDown={e => inputText(e)}
                      {...register(`Compradores.${index}.RepNombre`, {
                        required: true,
                        validate: (value) => {
                          if (value.length === 1) {
                            return false
                          } else {
                            return true
                          }
                        }
                      })}
                    />
                    {errors.Compradores?.[index]?.RepNombre && (
                      <span className="error">El nombre es requerido</span>
                    )}
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <label className="form-label bold">
                      Imagen frontal del documento de identidad
                    </label>
                    <DNIInput
                      id={`Compradores.${index}.RepImagenFrontal`}
                      register={register}
                      refText={`Compradores.${index}.RepImagenFrontal`}
                      fileName={datosTramiteLS?.datos_tramite?.[index] === undefined && datosTramiteLS?.dataCompradores?.Compradores?.[index] === undefined ? undefined : `Rep_Imagen_Frontal${datosTramiteLS?.idTramite}_${index + 1}`}
                      format={FileFormats.IMAGE}
                      formatError={"El archivo no es una Imagen"}
                      otherValidation={accion}
                    />
                    {errors.Compradores?.[index]?.RepImagenFrontal && (
                      <span className="error">{errors.Compradores?.[index]?.RepImagenFrontal.message}</span>
                    )}
                  </div>
                  <div className="col-md-6">
                    <label className="form-label bold">
                      Imagen dorsal del documento de identidad
                    </label>
                    <DNIInput
                      id={`Compradores.${index}.RepImagenDorsal`}
                      register={register}
                      refText={`Compradores.${index}.RepImagenDorsal`}
                      fileName={datosTramiteLS?.datos_tramite?.[index] === undefined && datosTramiteLS?.dataCompradores?.Compradores?.[index] === undefined ? undefined : `Rep_Imagen_Dorsal${datosTramiteLS?.idTramite}_${index + 1}`}
                      format={FileFormats.IMAGE}
                      formatError={"El archivo no es una Imagen"}
                      otherValidation={accion}
                    />
                    {errors.Compradores?.[index]?.RepImagenDorsal && (
                      <span className="error">{errors.Compradores?.[index]?.RepImagenDorsal.message}</span>
                    )}
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <label className="form-label bold">Vigencia de poder actualizada</label>
                    <FileInput
                      id={`Compradores.${index}.RepVigPod`}
                      register={register}
                      refText={`Compradores.${index}.RepVigPod`}
                      fileName={datosTramiteLS?.datos_tramite?.[index] === undefined && datosTramiteLS?.dataCompradores?.Compradores?.[index] === undefined ? undefined : `VIGENCIA_DE_PODER_${datosTramiteLS?.idTramite}_${index + 1}`}
                      format={FileFormats.PDF}
                      formatError={"El archivo no es un PDF"}
                      min={0}
                      minError={"El archivo no debe ser menor a 0 MB"}
                      max={4 * 1024 * 1024}
                      maxError={"El archivo no debe ser mayor a 4 MB"}
                      otherValidation={accion}
                    />
                    {errors.Compradores?.[index]?.RepVigPod && (
                      <span className="error">{errors.Compradores?.[index]?.RepVigPod?.message}</span>
                    )}
                  </div>
                  <div className="col-md-6">
                    <label className="form-label bold">Ficha RUC</label>
                    <FileInput
                      id={`Compradores.${index}.RepFichaRuc`}
                      register={register}
                      refText={`Compradores.${index}.RepFichaRuc`}
                      fileName={datosTramiteLS?.datos_tramite?.[index] === undefined && datosTramiteLS?.dataCompradores?.Compradores?.[index] === undefined ? undefined : `FICHA_RUC_${datosTramiteLS?.idTramite}_${index + 1}`}
                      format={FileFormats.PDF}
                      formatError={"El archivo no es un PDF"}
                      min={0}
                      minError={"El archivo no debe ser menor a 0 MB"}
                      max={4 * 1024 * 1024}
                      maxError={"El archivo no debe ser mayor a 4 MB"}
                      otherValidation={accion}
                    />
                    {errors.Compradores?.[index]?.RepFichaRuc && (
                      <span className="error">{errors.Compradores?.[index]?.RepFichaRuc?.message}</span>
                    )}
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-2">
                    <label className="form-label">Celular</label>
                    <input
                      type="number"
                      className="form-control"
                      onKeyDown={inputNumber}
                      {...register(`Compradores.${index}.RepTelefono`, {
                        required: 'El telefono es requerido',
                        validate: (value) => {
                          if (value.length !== 9) {
                            return 'El numero de telefono debe tener 9 digitos'
                          } else {
                            return true
                          }
                        }
                      })}
                    />
                    {errors.Compradores?.[index]?.RepTelefono && (
                      <span className="error">{errors.Compradores[index].RepTelefono.message}</span>
                    )}
                  </div>
                  <div className="col-md-4">
                    <label className="form-label">Email</label>
                    <input
                      type="email"
                      className="form-control"
                      {...register(`Compradores.${index}.RepEmail`, {
                        required: true,
                        validate: handleEmailValidation,
                      })}
                    />
                    {errors.Compradores?.[index]?.RepEmail &&
                      errors.Compradores?.[index]?.RepEmail.type ===
                      "required" && (
                        <p role="alert" className="error">
                          El email es requerido
                        </p>
                      )}
                    {errors.Compradores?.[index]?.RepEmail &&
                      errors.Compradores?.[index]?.RepEmail.type ===
                      "validate" && (
                        <p role="alert" className="error">
                          Email incorrecto
                        </p>
                      )}
                  </div>
                </div>
              </>
            )}
          </>
        )}
        {watch("Compradores").length > 1 && (
          <div className="col-12 mt-3">
            <button
              className="btn btn-outline-danger"
              onClick={(e) => {
                e.preventDefault();
                compradoresRemove(index);
              }}
            >
              Eliminar Comprador
            </button>
          </div>
        )}
      </div >
    </>
  );
}
