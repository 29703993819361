
import React, { useState } from "react";

import { ReactComponent as IconNew } from '../../resources/svg/new.svg';
import { OverlayTrigger, Tooltip } from "react-bootstrap";

export const Header = () => {

  const [showModalPreguntas, setShowModalPreguntas] = useState(false);
  const [showModalManual, setShowModalManual] = useState(false);

  const manualDocuments = [
    { item: 1, name: 'Pasos para crear una inmatriculacion Persona Natural', url: '/resources/manual1.pdf' },
    { item: 2, name: 'Pasos para crear una inmatriculacion Persona Juridica', url: '/resources/manual2.pdf' },
    { item: 3, name: 'Inmatriculacion con GLP', url: '/resources/manual3.pdf' },
    { item: 4, name: 'Proceso de prueba de vida', url: '/resources/manual4.pdf' },
    { item: 5, name: 'Proceso de firma digital', url: '/resources/manual5.pdf' },
  ];


  const modalManualesAbrir = () => {
    setShowModalManual(true);
  };

  const modalManualesCerrar = () => {
    setShowModalManual(false);
  };

  const modalPreguntasCerrar = () => {
    setShowModalPreguntas(false);
  };



  const modalPreguntasClickFuera = (e) => {
    if (e.target.classList.contains('modalOne')) {
      modalPreguntasCerrar()
    }
  };


  // Función para manejar la descarga del archivo
  const descargarManualFile = async (idFile) => {
    try {

      const itemManual = manualDocuments.find(manual => manual.item == idFile)
      const fileUrl = `${window.location.protocol}//${window.location.host}//${itemManual.url}` // 'https://example.com/archivo.pdf'; // Reemplaza con la URL de tu archivo 

      // Obtener el archivo usando fetch
      const response = await fetch(fileUrl);
      if (!response.ok) {
        throw new Error('Error al descargar el archivo');
      }

      // Convertir la respuesta a un Blob
      const blob = await response.blob();

      // Crear un enlace temporal para la descarga
      const url = window.URL.createObjectURL(blob);

      // Crear un elemento <a> dinámicamente
      const link = document.createElement('a');
      link.href = url;

      // Establecer el nombre del archivo
      link.setAttribute('download', 'archivo.pdf'); // Cambia el nombre según sea necesario

      // Simular un clic en el enlace para iniciar la descarga
      document.body.appendChild(link);
      link.click();
      link.remove();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Error al descargar el archivo:', error);
      alert('Hubo un problema al descargar el archivo. Por favor, inténtalo de nuevo.');
    }
  };


  return (
    <div>

      <ul className="nav justify-content-end" style={{ borderBottom: "0.1rem solid #ccc" }}>
        <li className="nav-item">
          <OverlayTrigger placement="bottom" overlay={<Tooltip>Aquí tendrás respuestas relacionadas con la inmatriculación vehicular</Tooltip>}>

            <a className="nav-link mouse" style={{ fontWeight: 700 }} onClick={(e) => setShowModalPreguntas(true)}>
              <span style={{ fontSize: "18px", color: "#2a2c9e" }}>Preguntas Frecuentes</span>
              <span style={{ animation: "parpadeo 2s infinite", fontWeight: 700 }} >
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="red" class="bi bi-bell-fill" viewBox="0 0 16 16">
                  <path d="M8 16a2 2 0 0 0 2-2H6a2 2 0 0 0 2 2m.995-14.901a1 1 0 1 0-1.99 0A5 5 0 0 0 3 6c0 1.098-.5 6-2 7h14c-1.5-1-2-5.902-2-7 0-2.42-1.72-4.44-4.005-4.901" />
                </svg>
              </span>


            </a>

          </OverlayTrigger>

        </li>

        <li className="nav-item">
          <OverlayTrigger placement="bottom" overlay={<Tooltip>Descarga aquí los manuales para realizar una inmatriculación</Tooltip>}>
            <a className="nav-link mouse" style={{ fontWeight: 700 }} onClick={(e) => modalManualesAbrir()} >

              <span style={{ fontSize: "18px", color: "#2a2c9e" }}>Manuales</span>

              <span style={{ animation: "parpadeo 2s infinite", fontWeight: 700 }} >
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="red" class="bi bi-bell-fill" viewBox="0 0 16 16">
                  <path d="M8 16a2 2 0 0 0 2-2H6a2 2 0 0 0 2 2m.995-14.901a1 1 0 1 0-1.99 0A5 5 0 0 0 3 6c0 1.098-.5 6-2 7h14c-1.5-1-2-5.902-2-7 0-2.42-1.72-4.44-4.005-4.901" />
                </svg>
              </span>

            </a>
          </OverlayTrigger>


        </li>
      </ul>




      <div className={`modalOne modal fade ${showModalPreguntas ? 'show' : ''}`} tabIndex="-1" style={{ display: showModalPreguntas ? 'block' : 'none' }} onClick={modalPreguntasClickFuera} >
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <FAQ modalPreguntasCerrar={modalPreguntasCerrar} />
            <div className="modal-footer">
              <button type="button" className="btn btn-danger" onClick={modalPreguntasCerrar}>
                Cerrar
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* Overlay */}
      {showModalPreguntas && <div className="modal-backdrop fade show"></div>}






      {showModalManual && (
        <div className="modal fade show" style={{ display: 'block', backgroundColor: 'rgba(0, 0, 0, 0.5)' }}>
          <div className="modal-dialog modal-dialog-centered" style={{ maxWidth: '600px' }} >
            <div className="modal-content  " style={{ borderRadius: '12px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.3)' }} >
              <div className="modal-header" style={{ borderBottom: '1px solid #e9ecef', padding: '20px', }} >
                <h4 className="modal-title" style={{ fontWeight: '600', color: "rgb(42, 44, 158)" }}>
                  Manuales Autohub
                </h4>
                <button
                  type="button" className="btn-close" onClick={modalManualesCerrar} aria-label="Cerrar" style={{ fontSize: '1rem' }}></button>
              </div>
              <div className="modal-body" style={{ padding: '20px' }}>
                <ul className="list-group">
                  {manualDocuments.map((doc, index) => (
                    <li
                      key={index}
                      className="list-group-item d-flex justify-content-between align-items-center"
                      style={{
                        border: '1px solid #e9ecef',
                        borderRadius: '8px',
                        marginBottom: '10px',
                        padding: '15px',
                      }}
                    >
                      <span style={{ fontWeight: '500' }}>{doc.name}</span>
                      <a className="btn  btn-outline-main"
                        download
                        style={{ borderRadius: '6px', padding: '8px 16px', fontSize: '14px', fontWeight: '500', }}
                        onClick={(e) => descargarManualFile(doc.item)}>
                        Descargar
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
              <div
                className="modal-footer"
                style={{
                  borderTop: '1px solid #e9ecef',
                  padding: '20px',
                  display: 'flex',
                  justifyContent: 'flex-end',
                }}
              >
                <button
                  type="button"
                  className="btn btn-danger"
                  onClick={modalManualesCerrar}
                  style={{
                    borderRadius: '8px',
                    padding: '10px 20px',
                    fontSize: '16px',
                    fontWeight: '500',
                  }}
                >
                  Cerrar
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      {showModalManual && <div className="modal-backdrop fade show"></div>}


    </div >


  );
};

export const FAQ = ({ modalPreguntasCerrar }) => {
  const [activeIndex, setActiveIndex] = useState(null);

  const cerrarModalPreguntas = () => {
    modalPreguntasCerrar()
  }

  const preguntas = [
    {
      pregunta: "¿Puedo realizar una inmatriculación con DNI caducado?", respuesta: `
      Sí, es posible iniciar un trámite con un DNI caducado. Sin embargo, es necesario realizar las siguientes verificaciones y consideraciones: <br/>
      <strong>1. Si el cliente ya inició la renovación de su DNI en RENIEC:</strong><br/>
        Se deberá comprobar con RENIEC la fecha de emisión reciente del documento.<br/>
        Informar al cliente que, una vez recoja su nuevo DNI, deberá enviar imágenes legibles del mismo para actualizar su información.<br/>
        En caso de que el cliente no envíe las imágenes del DNI vigente, no se procederá con la entrega de la placa correspondiente.
      <br/>
      <strong>2. Si el cliente aún no ha iniciado la renovación de su DNI:</strong><br/>
        Si no ha iniciado la renovacion de su DNI, se le recomienda que <strong>no inicie su renovación hasta que firme digitalmente en IOFE</strong>.
      `},
    {
      pregunta: "¿Es posible realizar una inmatriculación utilizando un Carné de Extranjería o Pasaporte caducado?", respuesta: `
       No, no es posible realizar una inmatriculación con un Carné de Extranjería o Pasaporte caducado. Para llevar a cabo este trámite, es obligatorio que el documento de identificación se encuentre vigente.`

    },
    {
      pregunta: "¿Cómo adjunto la partida de matrimonio en el sistema de inmatriculación?", respuesta: `


Es importante destacar que la partida de matrimonio deberá ser proporcionada únicamente cuando el estado civil registrado en su documento de identidad no coincida con su estado civil actual. A continuación, se detalla el procedimiento para adjuntar dicho documento:<br/><br/>

<strong>Verificación del documento:</strong> Antes de iniciar el proceso, asegúrese de que el certificado de matrimonio esté completo (conste de las dos páginas correspondientes) y sea legible.<br/>
<strong>Acceso a la sección de documentos adjuntos:</strong> En la pantalla de inmatriculación, diríjase al área de "Documentos Adjuntos" , ubicada en el lado derecho de la interfaz y representada por un ícono en forma de clip.<br/>
<strong>Agregar el documento:</strong> Haga clic en el botón "AGREGAR". Aparecerá un cuadro  en el que deberá seleccionar la opción correspondiente a "Partida de Matrimonio".
<strong>Carga del archivo:</strong> Una vez seleccionada la opción, proceda a subir el documento escaneado haciendo clic en "GUARDAR".
<strong>Confirmación de la carga:</strong> Finalmente, verifique que el documento haya sido cargado correctamente accediendo al área de "NOTARÍA", donde podrá visualizar el archivo adjunto.<br/><br/>
Siguiendo estos pasos, se garantizará que el proceso de adjuntar la partida de matrimonio se realice de manera correcta y eficiente.
 

`},
    {
      pregunta: "¿Cómo se adjunta una carta aclaratoria en el sistema de inmatriculación?", respuesta: `
      La carta aclaratoria se incluye únicamente cuando es necesario corregir un error en el registro de la inmatriculación. A continuación, se describe el procedimiento para adjuntar este documento:<br/>

      1. Acceda a la sección <strong>Documentos Adjuntos</strong> , ubicada en la parte derecha de la pantalla e identificada con un ícono de clip.<br/>
      2. Haga clic en el botón <strong>"Agregar"</strong> para abrir el cuadro de opciones.<br/>
      3. En el campo de selección, elija la opción <strong>"Aclaratorias"</strong> correspondiente.<br/>
      4. Suba el archivo de la carta aclaratoria y asegúrese de que esté correctamente cargado.<br/>
      5. Finalice haciendo clic en <strong>"Guardar"</strong> para confirmar la acción.
`
    },
    {
      pregunta: "¿Es posible realizar una inmatriculación en la que los compradores sean Co-propietarios?", respuesta: `
      Sí, es posible registrar una inmatriculación con copropietarios en AUTOHUB. Sin embargo, es importante tener en cuenta los siguientes requisitos:<br/><br/>
      <strong>Estado civil de los copropietarios:</strong> <br/>
      Para formalizar la copropiedad, los clientes deben encontrarse en uno de los siguientes estados civiles: Soltero, Divorciado, Viudo o Casado con Separación de Bienes.<br/>
      <strong>Prueba de vida y firma digital: </strong><br/>
      Ambos copropietarios deben realizar su prueba de vida correspondiente y firmar digitalmente en IOFE.<br/>
      <strong>Comprobantes de pago: </strong><br/>
      Los comprobantes de pago deben estar emitidos a nombre de ambos copropietarios.<br/><br/>
      **El cumplimiento de estos requisitos es indispensable para llevar a cabo el proceso de manera correcta y sin contratiempos.      
`},
    {
      pregunta: "¿Cuál es el plazo establecido para la inscripción de mi inmatriculación en SUNARP?", respuesta: `
      Una vez que el trámite haya alcanzado el estado <strong>"Presentado"</strong>, SUNARP cuenta con un plazo máximo de <strong>7 días hábiles</strong> para llevar a cabo la inscripción correspondiente del título. El conteo de este plazo inicia al día siguiente de la presentación del trámite.<br/>
      Es importante señalar que no se consideran días hábiles los sábados, domingos ni los días declarados como festivos. 
` },
    {
      pregunta: "¿¿Es posible registrar a una persona natural con RUC en la plataforma AUTOHUB?", respuesta: `
      Sí, es posible registrar a una persona natural con RUC 10 en AUTOHUB. A continuación, se detalla el procedimiento correspondiente:<br/><br/>
1. Al momento de realizar la inmatriculación, seleccione la opción "Persona Natural" en el sistema.<br/>
2. Ingrese el número de DNI del cliente para completar el registro.<br/>
3. Es importante tener en cuenta que el sistema no permite el registro directo utilizando un RUC 10. Sin embargo, sí es posible emitir una boleta asociada a un RUC de este tipo.<br/>
4. No será necesario adjuntar una carta aclaratoria para dicho proceso.<br/><br/>
Para garantizar una correcta gestión, se recomienda seguir estas indicaciones al utilizar la plataforma.
` }
  ];

  const toggleAccordion = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <>

      <div className="d-inline-flex pt-2">
        <h2 className="text-center p-2 fs-3" style={{ color: "#2a2c9e", width: "100%" }}> ¿ Preguntas Frecuentes ?</h2>
        <button type="button" className="btn-close float-end p-4 fs-8" aria-label="Cerrar" onClick={cerrarModalPreguntas} ></button>
      </div>


      <div className="container ">
        <div className="horizontal-spinner-container">
          <div className="horizontal-spinner"></div>
        </div>

        <div className="accordion shadow-lg rounded" style={{ fontSize: "0.8rem" }}>
          {preguntas.map((item, index) => (
            <div className="accordion-item" key={index}>
              <div className="accordion-header" onClick={() => toggleAccordion(index)}>
                {item.pregunta}
                <span className="accordion-icon">{activeIndex === index ? '-' : '+'}</span>
              </div>
              <div className={`accordion-body ${activeIndex === index ? 'show' : ''}`}>
                <div dangerouslySetInnerHTML={{ __html: item.respuesta }} />
              </div>
            </div>
          ))}
        </div>
      </div >
    </>



  );
};


export const DocumentDownloadModal = () => {

  const [showModalManual, setShowModalManual] = useState(false);
  const documents = [
    { name: 'Pasos para crear una inmatriculacion Persona Natural', url: '/documentos/documento1.pdf' },
    { name: 'Pasos para crear una inmatriculacion Persona Juridica', url: '/documentos/documento1.pdf' },
    { name: 'Inmatriculacion con GLP', url: '/documentos/documento2.docx' },
    { name: 'Prueba de vida', url: '/documentos/documento3.zip' },
  ];


  const modalManualesAbrir = () => {
    setShowModalManual(true);
  };

  const modalManualesCerrar = () => {
    setShowModalManual(false);
  };

  return (
    <>


      {showModalManual && (
        <div className="modal fade show" style={{ display: 'block', backgroundColor: 'rgba(0, 0, 0, 0.5)' }}        >
          <div className="modal-dialog modal-dialog-centered" style={{ maxWidth: '600px' }}          >
            <div className="modal-content  " style={{ borderRadius: '12px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.3)' }}            >

              <div className="d-inline-flex pt-2">
                <h1 className="text-center p-2" style={{ color: "#2a2c9e", width: "100%" }}>  Manuales Autohub</h1>
                <button type="button" className="btn-close float-end p-4" aria-label="Cerrar" style={{ fontSize: '1rem' }} onClick={modalManualesCerrar} ></button>
              </div>



              <div className="modal-body" style={{ padding: '20px' }}>
                <ul className="list-group">
                  {documents.map((doc, index) => (
                    <li
                      key={index}
                      className="list-group-item d-flex justify-content-between align-items-center"
                      style={{
                        border: '1px solid #e9ecef',
                        borderRadius: '8px',
                        marginBottom: '10px',
                        padding: '15px',
                      }}
                    >
                      <span style={{ fontWeight: '500' }}>{doc.name}</span>
                      <a
                        href={doc.url}
                        className="btn  btn-outline-main"
                        download
                        style={{
                          borderRadius: '6px',
                          padding: '8px 16px',
                          fontSize: '14px',
                          fontWeight: '500',
                        }}
                      >
                        Descargar
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
              <div
                className="modal-footer"
                style={{
                  borderTop: '1px solid #e9ecef',
                  padding: '20px',
                  display: 'flex',
                  justifyContent: 'flex-end',
                }}
              >
                <button
                  type="button"
                  className="btn btn-danger"
                  onClick={modalManualesCerrar}
                  style={{
                    borderRadius: '8px',
                    padding: '10px 20px',
                    fontSize: '16px',
                    fontWeight: '500',
                  }}
                >
                  Cerrar
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      {showModalManual && <div className="modal-backdrop fade show"></div>}
    </>
  );
};

