import React, { useState } from 'react'
import { Modal } from 'react-bootstrap'
import Swal from 'sweetalert2';

export const BandejaValidarTramite = ({showValidarArchivos,setShowValidarArchivos}) => {

    const [validarDatos, setValidarDatos] = useState({});

    


      const handleCloseValidarArchivo = async () => {
        setShowValidarArchivos(false);
    
        let mensajeAlerta = "prueba"
        if (true==mensajeAlerta) {
      //    onSubmitBuscar(watch());
    
    
          Swal.fire({
            title: "Tramite Validado",
            text: mensajeAlerta,
            icon: "success"
          });
        } else {
    
          Swal.fire({
            title: mensajeAlerta,
            icon: "error",
          });
        }
      };
    
    
    
  return (
    <div>

<Modal show={showValidarArchivos} onHide={handleCloseValidarArchivo} centered size="xl" animation={true}>
        <Modal.Header closeButton className="border-0">
          <Modal.Title className="text-center w-100 text-primary">Tus datos están correctos?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="container" style={{ fontFamily: 'Arial, sans-serif' }}>
            <div className="row">

              <div>
                {/* Sección de Inmatriculación */}
                <div className="mb-5">
                  <h4 className="text-center mb-4">Inmatriculación</h4>
                  <div className="card shadow-lg">
                    <div className="card-body">
                      <div className="d-flex">
                        <p className="me-4"><strong>Tipo:</strong> {validarDatos.inmatriculacion?.tipo}</p>
                        <p className="me-4"><strong>Representante:</strong> {validarDatos.inmatriculacion?.representante}</p>
                        <p><strong>GLP:</strong> {validarDatos.inmatriculacion?.glp}</p>
                      </div>
                      <h5 className="mt-1">Datos de Importación</h5>
                      <div className="d-flex">
                        <p className="me-4"><strong>Año:</strong> {validarDatos.inmatriculacion?.datosImportacion.ano}</p>
                        <p className="me-4"><strong>Puerto:</strong> {validarDatos.inmatriculacion?.datosImportacion.puerto}</p>
                        <p className="me-4"><strong>Régimen:</strong> {validarDatos.inmatriculacion?.datosImportacion.regimen}</p>
                        <p className="me-4"><strong>DUA:</strong> {validarDatos.inmatriculacion?.datosImportacion.dua}</p>
                        <p className="me-4"><strong>Item:</strong> {validarDatos.inmatriculacion?.datosImportacion.item}</p>
                      </div>
                    </div>
                  </div>
                </div>

                {/* Mostrar los compradores */}
                <div>
                  <h3 className="text-center mb-4">Lista de Compradores</h3>
                  <div className="row">
                    {validarDatos.comprador?.map((item) => (
                      <div key={item.numero} className="col-md-4 mb-4">
                        <div className="card h-100 shadow-lg">
                          <div className="card-body">
                            {/* Datos del comprador natural */}
                            {item.documento === "DNI" ? (
                              <>
                                <h5 className="card-title">
                                  {item.nombres} {item.apellidoPaterno} {item.apellidoMaterno}
                                </h5>
                                <p className="card-text"><strong>Documento:</strong> {item.documento} - {item.numero}</p>
                                <p className="card-text"><strong>Estado Civil:</strong> {item.estadoCivil}</p>
                                <p className="card-text"><strong>Distrito:</strong> {item.distrito}</p>
                                <p className="card-text"><strong>Domicilio:</strong> {item.domicilio}</p>
                                <p className="card-text"><strong>Celular:</strong> {item.celular}</p>
                                <p className="card-text"><strong>Email:</strong> {item.email}</p>

                                {/* Mostrar nueva información adicional */}
                                <p className="card-text"><strong>¿Unión Hecho?:</strong> {item.unionHecho || "No disponible"}</p>
                                <p className="card-text"><strong>Separación Patrimonial:</strong> {item.sepPatrimonios || "No disponible"}</p>
                                <p className="card-text"><strong>Partida</strong> {item.partida || "No disponible"}</p>
                                <p className="card-text"><strong>Oficina</strong> {item.oficina || "No disponible"}</p>


                                {/* Mostrar la información del cónyuge, si existe */}
                                {item.conyuge && (
                                  <>
                                    <hr />
                                    <h6 className="card-subtitle mb-2 text-muted">Cónyuge</h6>
                                    <p className="card-text"><strong>Nombre:</strong> {item.conyuge.nombres} {item.conyuge.apellidoPaterno} {item.conyuge.apellidoMaterno}</p>
                                    <p className="card-text"><strong>Documento:</strong> {item.conyuge.documento} - {item.conyuge.numero}</p>
                                    <p className="card-text"><strong>Estado Civil:</strong> {item.conyuge.estadoCivil}</p>
                                    <p className="card-text"><strong>Distrito:</strong> {item.conyuge.distrito}</p>
                                    <p className="card-text"><strong>Domicilio:</strong> {item.conyuge.domicilio}</p>
                                    <p className="card-text"><strong>Celular:</strong> {item.conyuge.celular}</p>
                                    <p className="card-text"><strong>Email:</strong> {item.conyuge.email}</p>
                                  </>
                                )}


                              </>
                            ) : (
                              // Datos de persona jurídica (empresa)
                              <>
                                <h5 className="card-title">{item.razonSocial}</h5>
                                <p className="card-text"><strong>RUC:</strong> {item.numero}</p>
                                <p className="card-text"><strong>Partida Registral:</strong> {item.partida}</p>
                                <p className="card-text"><strong>Oficina:</strong> {item.oficina}</p>
                                <p className="card-text"><strong>Distrito:</strong> {item.distrito}</p>
                                <p className="card-text"><strong>Domicilio:</strong> {item.domicilio}</p>

                                {/* Mostrar nueva información adicional */}
                                <p className="card-text"><strong>Oficina:</strong> {item.oficina || "No disponible"}</p>
                                <p className="card-text"><strong>Partida Registral:</strong> {item.partida || "No disponible"}</p>

                                <hr />

                                {/* Información del representante legal */}
                                <h6 className="card-subtitle mb-2 text-muted">Representante Legal</h6>
                                <p className="card-text"><strong>Nombre:</strong> {item.representante.nombres} {item.representante.apellidoPaterno} {item.representante.apellidoMaterno}</p>
                                <p className="card-text"><strong>Documento:</strong> {item.representante.documento} - {item.representante.numero}</p>
                                <p className="card-text"><strong>Celular:</strong> {item.representante.celular}</p>
                                <p className="card-text"><strong>Email:</strong> {item.representante.email}</p>


                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>

                {/* Sección del Vehículo */}
                <div className="mb-5">
                  <h4 className="text-center mb-4">Información del Vehículo</h4>
                  <div className="card shadow-lg">
                    <div className="card-body">
                      <div className="d-inline-flex me-4">
                        <p className="me-4"><strong>Marca:</strong> {validarDatos.vehiculo?.marca}</p>
                        <p className="me-4"><strong>Modelo:</strong> {validarDatos.vehiculo?.modelo}</p>
                        <p className="me-4"><strong>Año:</strong> {validarDatos.vehiculo?.anio}</p>
                      </div>
                      <div className="d-inline-flex me-4">
                        <p className="me-4"><strong>Color:</strong> {validarDatos.vehiculo?.color}</p>
                        <p className="me-4"><strong>Combustible:</strong> {validarDatos.vehiculo?.combustible}</p>
                        <p className="me-4"><strong>Motor:</strong> {validarDatos.vehiculo?.motor}</p>
                      </div>
                      <div className="d-inline-flex">
                        <p className="me-4"><strong>Peso Neto:</strong> {validarDatos.vehiculo?.pesoNeto} kg</p>
                        <p className="me-4"><strong>Carga Útil:</strong> {validarDatos.vehiculo?.cargaUtil} kg</p>
                        <p><strong>VIN:</strong> {validarDatos.vehiculo?.vin}</p>
                      </div>
                    </div>

                  </div>
                </div>
              </div>

            </div>
          </div>

        </Modal.Body>
        <Modal.Footer className="border-0 d-flex justify-content-center">
          <button
            className="btn btn-primary py-2"
            onClick={handleCloseValidarArchivo}
          >
            Confirmar
          </button>
        </Modal.Footer>
      </Modal>


    </div>
  )
}
